import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { I18nextContext, Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Privacy = (props) => {
    const context = React.useContext(I18nextContext);
    const { t } = useTranslation();
    const content = props.data.website.data_protection[context.language];

    return (
        <>
            <div className="place-content-center flex mx-5">
                <div className="max-w-4xl text-textColor pt-5">
                    <div className="flex h-56 w-56 place-self-center place-item-center">
                        <Link to='/'>
                            <GatsbyImage image={getImage(props.data.website.imgLogo)} alt={"Logo"} className="h-56 w-56" objectFit='scale-down' />
                        </Link>
                    </div>
                    <div style={{ color: `#${props.data.website.color_text_header}` }} className="text-5xl font-bold inline-block pt-6 pb-12">{t('Privacy')}</div>
                    <div style={{ color: `#${props.data.website.color_text_body}` }}>
                        <ReactMarkdown className="prose-base">{content}</ReactMarkdown>
                    </div>
                </div>
            </div>
            {/* <div className="mobile:hidden tablet:hidden">
                {" "}
                <FooterSectionDesktop website={props.data.website} />
            </div>
            <div className="desktop:hidden tablet:hidden ">
                {" "}
                <FooterSectionMobile website={props.data.website} />
            </div>
            <div className="desktop:hidden mobile:hidden ">
                {" "}
                <FooterSectionTablet website={props.data.website} />
            </div> */}
        </>
    );

};

export default Privacy;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                ns
                data
                language
                }
            }
        }
        website {
            name
            data_protection {
                de
                en
                it
            }
            contact {
                email
                telephone
            }
            address {
                city
                no
                street
                zip_code
            }
            color
            color_background
            color_text_body
            color_text_header
            supported_lang {
                de
                en
                it
            }
            min_delivery_value
            delivery_cost
            delivery_time
            default_locale
            imgLogo {
                childImageSharp {
                  gatsbyImageData(            
                    placeholder: BLURRED                    
                  )
                }
              }
        }
    }
`;
